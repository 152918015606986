<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader">
			<h3 class="buyerHeaderTit">{{$t('i18nn_e8deb376cbd8558f')}}</h3>
		</div> -->
		<div class="tableConTop">
		  <el-row>
		    <el-col :span="5" class="tableConTopLeft">
		      <h3>
		        <span class="tct_tit">{{$t('i18nn_e8deb376cbd8558f')}}</span>
		      </h3>
		    </el-col>
		    <el-col :span="19" class="tableConTopRig">
					<span style="padding-right: 10px;" v-if="showAdd">
						<el-button type="success" @click="openDioalog" size="small" icon="el-icon-plus">{{ $t('c0246c55b9cac963') }}</el-button>
						<el-button type="danger" @click="delAction($event, null)" size="small" icon="el-icon-delete">{{$t('e33c9b93c36fd250')}}</el-button>
					</span>
					
		      <el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
		    </el-col>
		  </el-row>
		</div>
		
		<div class="filterCon" style="">
			<ul class="filterConList">
				<li>
					<span>{{ $t('hytxs0000060') }}</span>
					<!-- <cusSelFuzzyAll @changeData="changCus"></cusSelFuzzyAll> -->
					<cusSelFuzzy @changeData="changCus"></cusSelFuzzy>
				</li>
				<li>
					<span>{{ $t('c944a6686d996ab3') }}</span>
					<!-- <el-select size="small" filterable clearable v-model="filterData.whNo" :placeholder="$t('2ad108ab2c560530')" style="width: 200px;" @change="initData()">
						<el-option v-for="item in selectOption.wh_no" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
								<span class="sel_option_code" style="">{{ item.code }}</span>
							</div>
						</el-option>
					</el-select> -->
					<!-- <whNoAllSelect ref="whNoAllSelect" @changeData="changWhNo" :width="200"></whNoAllSelect> -->
					<whNoSelect ref="whNoAllSelect" @changeData="changWhNo" :width="200"></whNoSelect>
				</li>
				<li>
					<el-button icon="el-icon-search" size="small" type="primary" @click="initData()">{{ $t('1e7246dd6ccc5539') }}</el-button>
				</li>
			</ul>
		</div>
		
		<!-- <div class="wh-filter-con">
			<div class="wh-filter-item">
				<span>{{ $t('hytxs0000060') }}</span>
				<cusSelFuzzyAll @changeData="changCus"></cusSelFuzzyAll>
			</div>
			<div class="wh-filter-item">
				<span>{{ $t('c944a6686d996ab3') }}</span>
				<el-select size="small" filterable clearable v-model="filterData.whNo" :placeholder="$t('2ad108ab2c560530')" style="width: 200px;" @change="initData()">
					<el-option v-for="item in selectOption.wh_no" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
						<div class="sel_option_s1" style="">
							<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
							<span class="sel_option_code" style="">{{ item.code }}</span>
						</div>
					</el-option>
				</el-select>
			</div>
			<el-button icon="el-icon-search" size="small" type="primary" @click="initData()">{{ $t('1e7246dd6ccc5539') }}</el-button>
		</div> -->

		<!-- <div class="formMsgCon">
        <h3><i class="titleIcon el-icon-bell"></i>{{$t('i18nn_41413cddfa61538c')}}</h3>
        <div class="formMsgDet">
          <p>
            此为录入仓库的SKU数据
          </p>
        </div>
    </div> -->

		<!-- <div class="wh-filter-con" v-if="this.cusUserId">
      <div class="wh-filter-item">
          <span>{{$t('Storage.skuInfo.category')}}:</span>
          <el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.subjectName" maxlength="50" :clearable="true" style="width: 150px;">
          </el-input>

        </div>
      <div class="wh-filter-item">
          <span>SKU:</span>
          <el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.goodsSku" maxlength="50" :clearable="true" style="width: 150px;">
          </el-input>
        </div>
        <div class="wh-filter-item">
            <span>{{$t('Storage.skuInfo.Chinese_title')}}:</span>
            <el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.goodsName" maxlength="50" :clearable="true" style="width: 150px;">
            </el-input>
          </div>
          <div class="wh-filter-item">
              <span>{{$t('Storage.skuInfo.English_title')}}:</span>
              <el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.goodsNameEn" maxlength="50" :clearable="true" style="width: 150px;">
              </el-input>
            </div>
        <el-button type="primary" @click="serPageData" size="small">{{$t('FormMsg.Search')}}</el-button>

    </div> -->

		<!-- <div class="wh-action-con">
			<el-button type="success" @click="openDioalog" size="small" icon="el-icon-plus">{{ $t('c0246c55b9cac963') }}</el-button>
			<el-button type="danger" @click="delAction($event, null)" size="small" icon="el-icon-delete">{{$t('e33c9b93c36fd250')}}</el-button>
		</div> -->

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table id="out-table2" ref="multipleTable" :data="tableData" stripe :border="true" :height="$store.state.tableMaxHeight2"
			 @selection-change="handleSelectionChange"
			 @row-click="handleCurrentChange"
			 style="width: 100%" size="small">
				<el-table-column type="selection" fixed="left" align="center" width="55" v-if="showAdd"></el-table-column>
				<el-table-column type="index" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>
				
				
				<el-table-column prop="status" :label="$t('6cdece641436d7ab')">
					<template slot-scope="scope">
						<div>
							<el-tag type="success" v-if="'1' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
							<el-tag type="danger" v-else-if="'0' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
							<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="userId" :label="'客户ID'"></el-table-column>
				<el-table-column prop="cusName" :label="$t('hytxs0000060')" show-overflow-tooltip></el-table-column>

				<el-table-column prop="whNo" :label="$t('5acbec83efb27445')"></el-table-column>
				<el-table-column prop="whNoName" :label="$t('i18nn_9d8eca19feef6cb6')"></el-table-column>

				<el-table-column prop="remark" :label="$t('15b3627faddccb1d')">
					<template slot-scope="scope">
						<el-popover placement="top"
					    trigger="hover"
					    ><div class="pre-text">{{ scope.row.remark }}</div>
					    <span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
					  </el-popover>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="updateTime" :label="$t('i18nn_fdc34fd7121f9c48')"></el-table-column> -->
				
				<!-- <el-table-column prop="createTime" :label="$t('i18nn_726c51702f70c486')"></el-table-column> -->
				
				<el-table-column prop="" :label="$t('i18nn_43a3586339251494')" min-width="200">
					<template slot-scope="scope">
						<div>
							<ul>
								<li>
									<span>{{$t('i18nn_e1911e9360047dcb')}}</span>:{{scope.row.updateTime}}
								</li>
								<li>
									<span>{{$t('i18nn_e8564657bbe9ca53')}}</span>:{{scope.row.createTime}}
								</li>
							</ul>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column :label="$t('Storage.tableColumn.operation')" width="200px" fixed="right">
					<template slot-scope="scope">
						<!-- <div v-if="isSel"><el-button @click="selRow($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-magic-stick">{{$t("FormMsg.Select")}}</el-button></div> -->
						<!-- <div v-else> -->
						<!-- <el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-edit">{{$t("FormMsg.Edit")}}</el-button> -->
						<!-- <el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button> -->
						<!-- </div> -->
						
						<div>
							<el-button @click="openAction($event, scope.row)" type="success" size="mini" icon="el-icon-check">{{$t('70fdf36bdc2b0f58')}}</el-button>
								
							<el-button @click="closeAction($event, scope.row)" type="danger" size="mini" icon="el-icon-close">{{$t('4e9fc68608c60999')}}</el-button>
							
						</div>
					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div>
		</div>
		<!-- 修改弹窗 -->
		<!-- <el-dialog :close-on-click-modal="false" top="10px" :title="$t('c0246c55b9cac963')" append-to-body :visible.sync="dialogFormVisible" width="1000px"> -->
		<el-drawer :wrapperClosable="false" :title="$t('c0246c55b9cac963')" append-to-body :visible.sync="dialogFormVisible" :direction="'rtl'" :size="500">
			<div>
				<el-form ref="form" :rules="formRules" :model="form" label-width="150px" v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">
					<el-form-item :label="$t('hytxs0000060')" prop="" required>
						<!-- <el-button @click="openSelDialog()" type="warning" icon="el-icon-magic-stick">{{$t("FormMsg.Select")}}</el-button>
						<el-input type="text" disabled v-model="form.userName" style="width: 200px;"></el-input>
						<el-input type="text" disabled v-model="form.bckId" style="width: 200px;"></el-input> -->

						<!-- <span>{{ $t('hytxs0000060') }}</span> -->
						<cusSelFuzzyAll ref="cusSelFuzzyAll" @changeData="changCus2"></cusSelFuzzyAll>
						<!-- <cusSelFuzzy ref="cusSelFuzzyAll" :size="''" @changeData="changCus2"></cusSelFuzzy> -->
					</el-form-item>

					<el-form-item :label="$t('c944a6686d996ab3')" prop="" required>
						<!-- <el-input type="text" v-model="form.whNo" style="width: 300px;" :placeholder="$t('FormMsg.Please_Enter')"></el-input> -->
						<el-button type="primary" @click="addRow()" icon="el-icon-plus">{{$t('c0246c55b9cac963')}}</el-button>
						
						<ul>
							<li v-for="(item,index) in form.whNoArr" :key="index">
								<!-- <el-button type="link" @click="delRow($event,item,index)">{{$t('e33c9b93c36fd250')}}</el-button> -->
								<el-link type="primary" @click="delRow($event,item,index)">{{$t('e33c9b93c36fd250')}}</el-link>
								<span>{{item.whName}}({{item.whNo}})</span>
							</li>
						</ul>
						
						<!-- <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">{{$t('i18nn_3a7c1bda93906fe9')}}</el-checkbox>
						<div style="margin: 15px 0;"></div>
						<el-checkbox-group v-model="form.whNos" @change="handleCheckedCitiesChange">
							<el-checkbox v-for="item in selectOption.wh_no" :label="item.code" :key="item.code">{{ $Utils.i18nKeyText(item,'codeText') }}</el-checkbox>
						</el-checkbox-group> -->

						<!-- <el-select filterable clearable v-model="form.whNo" :placeholder="$t('2ad108ab2c560530')" style="width: 200px;">
							<el-option v-for="item in selectOption.wh_no" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
								<div class="sel_option_s1" style="">
									<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
									<span class="sel_option_code" style="">{{ item.code }}</span>
								</div>
							</el-option>
						</el-select> -->
						<!-- <whNoSelect @changeData="changeDataWhNo" :width="200"></whNoSelect> -->
					</el-form-item>

					<el-form-item :label="$t('Storage.tableColumn.remark')" prop="">
						<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark" :maxlength="1000" show-word-limit></el-input>
					</el-form-item>
				</el-form>
			</div>

			<div class="drawer-footer">
				<el-button type="primary" plain @click="dialogFormVisible = false">{{ $t('FormMsg.Close') }}</el-button>
				<el-button type="primary" icon="el-icon-tickets" @click="submitForm('form')">{{ $t('FormMsg.Save') }}</el-button>
			</div>
		</el-drawer>

		<!--选择账号-->
		<!-- <el-dialog :title="$t('i18nn_b17342e22700eb32')" append-to-body :close-on-click-modal="false" :visible.sync="dialogSelVisible" width="800px" top="0px">
			<div>
				<AccountUserList @selectRow="selAccount"></AccountUserList>
			</div>
      <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogSelVisible = false">{{$t('FormMsg.Close')}}</el-button></div>
    </el-dialog> -->
		
		<!-- 选择 -->
		<dialogWarehouse :openTime="whOpenTime"  @sure="sureSel"></dialogWarehouse>
		
	</div>
</template>
<script>
// import AccountUserList from '@/components/WarehouseCenter2/AccountBind/AccountUserList.vue';
// import whNoAllSelect from '@/components/WarehouseCenter2/components/whNoAllSelect.vue';
import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';
import cusSelFuzzyAll from '@/components/WarehouseCenter2/components/cusSelFuzzyAll.vue';

import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';

import dialogWarehouse from '@/components/WarehouseCenter2/SysConfig/dialogWarehouse.vue';
// import excelUtilsNew from '@/utils/excelUtilsNew.js';
// import barcode from '@/components/Common/barcode.vue';
// import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';
export default {
	// name: 'BuyerOrder',
	//meta信息seo用
	// metaInfo: {
	//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
	//   meta: [{ // set meta
	//     name: '互易天下-买家中心-收货账单',
	//     content: '互易天下-厂家共享平台-买家中心-收货账单'
	//   }],
	//   // link: [{                 // set link
	//   //   rel: 'asstes',
	//   //   href: 'https://assets-cdn.github.com/'
	//   // }]
	// },
	// props: ['isDialog'],
	components: {
		// AccountUserList,
		cusSelFuzzyAll,
		cusSelFuzzy,
		// whNoAllSelect,
		whNoSelect,
		dialogWarehouse
		// whNoSelect
	},
	props:{
	  // mobile:"",
	  showAdd:{
	    default: function() {
	      return false
	    },
	    type: Boolean
	  },
	},
	data() {
		return {
			// cusUserId: '',
			whOpenTime:'',
			// UserInfo: this.$store.getters.getUserInfo,
			dialogFormVisible: false,
			dialogFormStatus: 0, //0-新增，1-修改

			dialogSelVisible: false,
			multipleSelection:[],
			// CateValue: [],
			loading: false,

			// checkAll: false,
			// isIndeterminate: false,
			
			form: {
				// id: null, //"数据ID",
				userId: '', //
				// userName:'',
				whNos: [], //
				whNoArr:[],
				remark: ''
			},

			formRules: {
				userId: [{ required: true, message: this.$t('FormMsg.Please_select'), trigger: 'change' }],
				whNos: [{ required: true, message: this.$t('FormMsg.Please_select'), trigger: 'change' }]
			},
			loading_load: false,
			tableData: [],
			//分页数据
			pagination: this.$Utils.defaultPagination(),
			selectOption: {
				// wh_no: []
			},
			//查询，排序方式
			filterData: {
				orderBy: 'id_', //排序字段
				sortAsc: 'desc', //desc降序，asc升序
				userName: '',
				userId:'',
				whNo: ''
			}
		};
	},

	//创建时
	created() {
		// this.getPageData();
	},
	//编译挂载前
	mounted() {
		// this.getDicData();
		this.initData();
		// this.getDicData();
	},
	methods: {
		initData() {
			this.pagination.current_page = 1;
			// this.currentSel = {};
			this.multipleSelection = [];
			this.getPageData();

			// this.getWhNoListData();
		},

		changCus(data) {
			console.log('changCus', data);

			this.filterData.userId = data.userId;
			// this.filterData.userName = data.companyName;
			this.initData();
		},

		changCus2(data) {
			console.log('changCus', data);

			this.form.userId = data.userId;
			// this.filterData.userName = data.companyName;
			// this.initData();
		},
		changWhNo(data) {
			console.log('changWhNo', data);
			this.filterData.whNo = data.code;
			// this.filterData.userId = data.userId;
			this.initData();
		},
		// handleCheckAllChange(val) {
		// 	console.log('handleCheckAllChange',val);
		// 	let cityOptions = this.selectOption.wh_no.map(v => v.code);
		// 	console.log('cityOptions',cityOptions);
		// 	this.form.whNos = val ? cityOptions : [];
		// 	this.isIndeterminate = false;
		// },
		// handleCheckedCitiesChange(value) {
		// 	console.log('handleCheckedCitiesChange',value);
		// 	let checkedCount = value.length;
		// 	this.checkAll = checkedCount === this.selectOption.wh_no.length;
		// 	this.isIndeterminate = checkedCount > 0 && checkedCount < this.selectOption.wh_no.length;
		// },
		addRow() {
			this.whOpenTime = new Date().getTime();
		},
		clearRow(){
			this.form.whNoArr = [];
		},
		//删除
		delRow(event, row, index) {
			this.form.whNoArr.splice(index, 1);
		},
		
		sureSel(val){
			// this.form.whNoArr = val;
			
			// this.form.whNos = val.map(v => v.whNo);
			val.forEach(item=>{
				this.form.whNoArr.push(item);
			});
		},
		
		//打开账号选择弹窗
		// openSelDialog(){
		// 	this.dialogSelVisible = true;
		// },
		//选择仓库
		// changeDataWhNo(data){
		// 	console.log('changeDataWhNo',data);
		// 	this.form.whNo = data.code;
		// },
		//选择账号
		// selAccount(row){
		// 	console.log('selAccount',row);
		// 	this.form.bckId = row.userId;
		// 	this.form.userName = row.userName;
		// 	this.dialogSelVisible = false;
		// },
		//导出 excel
		// exportExcel() {

		// },
		//跳转页面
		// toPageUrl(name){
		//   this.$router.push({'name':name});
		// },
		//打开新增编辑，弹窗
		openDioalog(formParm) {
			// console.log(formParm);
			this.dialogFormVisible = true;
			
			// this.checkAll = false;
			// this.isIndeterminate = false;
			
			let form = Object.assign({}, formParm);
			console.log('form', form);
			// // 重置
			this.resetForm('form');
			// if (null === formParm) {
			//新增
			this.form.userId = '';
			this.$nextTick(()=>{
				this.$refs.cusSelFuzzyAll.clearData();
			})
			// this.form.userName = '';
			// this.form.whNoArr = [];
			this.form.whNos = [];
			this.form.whNoArr = [];
			this.form.remark = '';

			this.dialogFormStatus = 0;
			// form.subUserId = null;
			// form.userSubUserId = null;
			// form.state = true;
			//浅拷贝、对象属性的合并
			// this.form = form;
			// } else {
			// 	//修改
			// 	// this.dialogFormStatus = 1;
			// 	// // form.state = form.state === '0' ? true : false;
			// 	// // this.form.goodsSubjectId = this.CateValue[0];
			// 	// this.CateValue = [form.goodsSubjectId];
			// 	// //浅拷贝、对象属性的合并
			// 	// this.form = form;
			// }
			// this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			//       type: 'warning',
			//       //confirmButtonText: this.$t('204ffab8a6e01870'),
			//     });
		},
		//打开编辑
		// openEdit(event, row, index) {
		//   event.stopPropagation();
		//   this.openDioalog(row, this.$t('FormMsg.Edit'));
		// },
		//选择行
		handleCurrentChange(row, event, column) {
			// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
		},
		//多选
		handleSelectionChange(val) {
			console.log(val);
			this.multipleSelection = val;
		},
		//删除
		delAction(event, row) {
			let parm = [];
			
			if (row) {
				//单条
				parm = [row.id];
			} else {
				//多条
				let dataList = this.multipleSelection;
				if (dataList.length < 1) {
					this.$message.warning(this.$t('7b80e66b535a3732'));
					return;
				}
			
				let dataListParm = dataList.map(v => v.id);
				parm = dataListParm;
				// console.log('dataListParm', dataListParm);
				// let dataParm = {
				//   ids: dataListParm
				// };
			}
			
			this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
				// confirmButtonText: this.$t('204ffab8a6e01870'),
				// cancelButtonText: this.$t('4b3340f7872b411f'),
				type: 'warning'
			})
				.then(() => {
					
					this.delDataAction(event, parm);
				})
				.catch(() => {
					
				});
		},
		//删除
		delDataAction(event, parm) {
			event.stopPropagation();
			console.log('delDataAction', row);
			this.postData(this.$urlConfig.WhCusBindDel, {ids: parm} , '');
		},
		
		closeAction(event, row){
			event.stopPropagation();
			this.postData(this.$urlConfig.WhAccountBindClose, {id:row.id}, '');
		},
		
		openAction(event, row){
			event.stopPropagation();
			this.postData(this.$urlConfig.WhAccountBindOpen, {id:row.id}, '');
		},
		
		//提交信息
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					let formData = Object.assign({}, this.form);
					formData.whNos = formData.whNoArr.map(item=>item.whNo);
					//浅拷贝、对象属性的合并
					if (0 === this.dialogFormStatus) {
						// formData.id = null;
						// formData.userName = null;

						this.postData(this.$urlConfig.WhCusBindAdd, formData);
					} else {
						// formData.userId = this.UserInfo.id;
						// this.postData(this.$urlConfig.WhMyGoodsSkuUpdate+'/'+this.cusUserId, formData);
					}
				} else {
					console.log('error submit!!');
					this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('204ffab8a6e01870'),
					});
					return false;
				}
			});
		},
		//重置输入框
		resetForm(formName) {
			console.log(formName);
			// console.log(this.$refs[formName]);

			if (this.$refs[formName]) {
				this.$refs[formName].resetFields();
			} else {
				console.log('this.$refs[formName]', this.$refs[formName]);
			}
			// this.CateValue = [];
			// if (this.$refs.hyUpLoadImg1) {
			// 	this.$refs.hyUpLoadImg1.initUrl('');
			// }
		},

		//提交信息
		postData(url, formData, type) {
			// let _this = this;
			this.loading = true;
			this.loading_load = true;
			let HttpType = {};
			if ('delete' == type) {
				HttpType = this.$http.delete(url, formData);
			} else {
				HttpType = this.$http.put(url, formData);
			}
			// formData.state = formData.state ? '0' : '1';
			HttpType.then(({ data }) => {
				console.log(this.$t('tips.submitSuccess'));
				console.log(data);
				
				this.loading = false;
				this.loading_load = false;
				if (200 == data.code) {
					this.dialogFormVisible = false;
					this.getPageData();
					// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
					//   type: 'success',
					//   //confirmButtonText: this.$t('204ffab8a6e01870'),
					// });
					// this.$message.success(this.$t('tips.submitSuccess'));
					if ('delete' == type) {
						this.$message({
							type: 'success',
							message: this.$t('tips.successfullyDelete')
						});
					} else {
						// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
						// 	type: 'success'
						// });
						this.$message.success(this.$t('tips.submitSuccess'));
					}
				} else {
					// if (!data.msg) {
					//   data.msg = this.$t('tips.submitError');
					// }
					this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('204ffab8a6e01870'),
					});
				}
			}).catch(error => {
				console.log(error);
				console.log(this.$t('tips.submitError'));
				this.loading = false;
				this.loading_load = false;
				this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
					type: 'warning'
					//confirmButtonText: this.$t('204ffab8a6e01870'),
				});
			});
		},

		//查询数据
		serPageData() {
			this.pagination.current_page = 1;
			this.getPageData();
		},

		//请求分页数据
		getPageData() {
			// let _this = this;
			this.loading_load = true;

			this.$http
				.put(this.$urlConfig.WhCusBindPageList, {
					// sortAsc: this.filterData.sortAsc,
					// orderBy: this.filterData.orderBy,
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size, //当前页显示数目

					// states: this.filterData.status ? this.filterData.status : null,
					// plNo: this.filterData.plNo ? this.filterData.plNo : null,
					//
					// userId: this.filterData.userId ? this.filterData.userId : null,
					// userId: this.cusUserId,
					userId: this.filterData.userId ? this.filterData.userId : null,
					whNo: this.filterData.whNo ? this.filterData.whNo : null
					// goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null
					// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
				})
				.then(({ data }) => {
					// console.log(this.$store.getters.getUserInfo);
					console.log('分页，请求成功');
					console.log(data);
					
					this.loading_load = false;
					//表格显示数据
					this.tableData = data.rows;
					//当前数据总条数
					this.pagination.total = parseInt(data.total);
					//当前页数
					// this.pagination.current_page = parseInt(data.current);
					//当前页条数
					// this.pagination.page_size = parseInt(data.size);
				})
				.catch(error => {
					console.log(error);
					console.log('分页，请求失败');
					this.loading_load = false;
				});
		},

		//选择数据后回调
		// selRow(event, row) {
		// 	event.stopPropagation();
		// 	this.$emit('selectRow', row);
		// },
		//请求
		// getWhNoListData() {
		// 	this.loading = true;
		// 	this.$http
		// 		.get(this.$urlConfig.whNoPageList, {})
		// 		.then(({ data }) => {
		// 			console.log('名称搜索，请求成功');
		// 			console.log(data);
		// 			
		// 			this.loading = false;
		// 			if (200 == data.code) {
		// 				this.selectOption.wh_no = data.rows;
		// 			} else {
		// 				this.$message.warning(data.msg ? data.msg : this.$t('i18nn_323ee425eca7208c'));
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log('搜索，请求失败');
		// 			this.$message.error('请求失败！');
		// 			this.loading = false;
		// 		});
		// }
		//查询数据字典
		// getDicData() {
		// 	this.$http
		// 		.put(this.$urlConfig.HyDicQueryList, ['wh_no'])
		// 		.then(({ data }) => {
		// 			console.log('查询数据字典，请求成功');
		// 			console.log(data);
		// 			if (200 == data.code && data.data) {
		// 				this.selectOption.wh_no = data.data['wh_no'];
		// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
		// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
		// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
		// 			} else {
		// 				if (!data.msg) {
		// 					data.msg = this.$t('tips.submitError');
		// 				}
		// 				this.$message.warning(data.msg);
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log('查询数据字典接口，请求失败');
		// 			this.$message.error(this.$t('tips.submitRequestError'));
		// 		});
		// }
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import url(@/assets/css/client_module.less);

// /deep/ .el-input-number,
//   .el-select {
//     // width: 100px;
//     .el-input__inner {
//       text-align: left;
//     }
//   }
// .form_msg {
//   color: #e6a23c;
// }
</style>
